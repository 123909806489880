body {
    font-family: 'Montserrat', sans-serif;
    /* background-color: #eee; */
}

/* navbar */
.sidebar {
    height: 100vh;
    background-color: grey;
    box-shadow: 5px 7px 25px #999;
}

.bottom-border {
    border-bottom: 1px groove #eee;
}

.sidebar-link {
    transition: all .4s;
}

.sidebar-link:hover {
    background-color: #444;
    border-radius: 5px;
}

.current {
    background-color: #f44336;
    border-radius: 7px;
    box-shadow: 2px 5px 10px #111;
    transition: all .3s;
}

.current:hover {
    background-color: #f66436;
    border-radius: 7;
    box-shadow: 2px 5px 20px #111;
    transform: translateY(-1px);
}

.top-title {
    background-color: darkslategray;
}

@media(max-width: 768px) {
    .sidebar {
        position: static;
        height: auto;
    }
    .top-title {
        position: static;
    }
}