.title {
    text-align: center;
    margin: 2rem;
}

.babysit-container {
    background-color: rgb(5, 136, 64);
    border-style: solid;
    border-width: 5px;
    border-color: 	rgb(193, 154, 107);
    border-radius: 25px;
    text-align: center;
    height: 100vh;
    width: 100vw;
    margin: auto;
    font-size: 22px;
    font-family: 'Chalk';
    color: white;
}

.responsiveCal {
 
    position: relative; padding-bottom: 75%; height: 0; overflow: hidden;
     
    }
     
    .responsiveCal iframe {
     
    position: absolute; top:0; left: 0; width: 100%; height: 100%;
     
    }

@media (max-width: 479px) {
    .calendar {
        width: 600px !important;
    }
}

/* @media (max-width: 1000px) {

} */
/* @media (max-width: 1000px) {
    .card {
        width: 50%;
        height:50%;
        font-size: 50%;
    }
}

@media (max-width: 479px) {

} */

