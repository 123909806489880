.carousel {
    border-style: solid;
    border-width: 5px;
    border-top-width: 5px;
    border-right-width: 5px;
    border-bottom-width: 5px;
    border-left-width: 5px;
    border-color: white;
    border-radius: 25px;
    text-align: center;
    height: 100%;
    width: 60vw;
    max-width:100%;
    max-height:100%;
    margin: auto;
    background-color: grey;
}

.carousel {
    width: 870px;
    height: 480px;
  }

.carousel-inner {
    border-radius: 20px;
}



.img {
    position: relative;
    float: left;
    /* width:  100%; */
    height: 470px;
    background-position: 50% 50%;
    background-repeat:   no-repeat;
    background-size:     cover;
}

.portrait {
    height: 470px !important;
    width: auto !important;
    position: relative;
    display:block !important;
    margin:auto !important;
    /* text-align: center; */
    left: 50%;
    /* right: 50%; */
    transform: translate(-50%)
}
/* 
img {
    max-height: 100vh;
    width: auto   9;
    height: auto;
    vertical-align: middle;
    border: 0;
    -ms-interpolation-mode: bicubic;
  } */

.fa-chevron-circle-left,
.fa-chevron-circle-right  {
    font-size: 50px;
    /* color: grey; */
}

@media (max-width: 479px) {
    .carousel {
        width: 460px;
        height: 270px;
    }
    .img {
        width: 450px;
        height: 260px;
    }
    .portrait {
        height: 260px !important;
        width: auto !important;
    }
}