.title {
    text-align: center;
    margin: 2rem;
}

.container-inner-contact {
    background-color: rgb(5, 136, 64);
    border-style: solid;
    border-width: 5px;
    border-color: 	rgb(193, 154, 107);
    border-radius: 25px;
    text-align: left;
    height: 80vh;
    width: 80vw;
    margin: auto;
    padding-left: 1.5em;
    padding-right: 1.5em;
    font-size: 22px;
    font-family: 'Chalk';
    color: white;
    overflow-y: scroll;
    /* font-size: 2vw; */
}

.container-inner-contact::-webkit-scrollbar {
    -webkit-appearance: none;
}

.container-inner-contact::-webkit-scrollbar:vertical {
    width: 11px;
}

.container-inner-contact::-webkit-scrollbar-thumb {
    border-radius: 8px;
    /* border: 2px solid white; */
    background-color: rgba(0, 0, 0, .4);
}

.home-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 20%;
}

.contact-title {
    font-size: .8em;
    text-align: left;
}

.inline {
    display: flex;
    width: 50%;
    /* margin: 10px; */
    font-size: 1em;
    padding: 10px;
}

.message-text {
    width: 50%; 
    font-family: Arial;
    font-size: .7em;
}

@media (max-width: 1000px) {
    .message-text {
        width: 100%; 
        font-family: Arial;
        font-size: .7em;
    }
}

@media (max-width: 479px) {
    .message-text {
        width: 100%; 
        font-family: Arial;
        font-size: .7em;
    }
    .container-inner-contact {
        height: 650px;
    }
}

