.form-title {
    font-size: 14px;
    margin-bottom: 0;
}

.form-line {
    padding-bottom: 10px;
}

.topic-header {
    color: rgb(37, 134, 167);
    font-weight:bold;
}

.topBG {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-size: auto 100%; 
  height: 100%;
  width: 100%;
}

.container-inner-form {
    background-color: rgba(197, 239, 247, .7);
    /* border-style: solid; */
    /* border-width: 5px; */
    /* border-color: 	black; */
    border-radius: 25px;
    text-align: center;
    height: 100%;
    width: 80vw;
    margin: auto;
    font-size: 25px;
    font-family: 'Chalk';
    /* color: white; */
    /* font-size: 2vw; */
}

.section-header {
    border-bottom: 1px solid #999;
    margin: .5em 0;
    padding-bottom: .1em;
    font-size: 26px;
}

.form-style {
    font-family: 'Times New Roman', Times, serif;
    text-align: left;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 18px;
}

.field-element {
    width: 100%;
    /* padding: 12px; */
    /* margin: 6px 0 4px; */
    /* border: 1px solid #ccc; */
    /* background: #fafafa; */
    color: #000;
    font-family: sans-serif;
    font-size: 12px;
    line-height: normal;
    box-sizing: border-box;
    border-radius: 2px;
    display: flex;
}

.inline {
    display: flex;
    width: 50%;
    /* margin: 10px; */
    font-size: 14px;
    padding: 10px;
}

.inline-2 {
    display: flex;
    width: 3.5em;
    margin-right: 5px;
    font-size: 14px;
    padding: 10px;
}

.inline-4 {
    display: flex;
    width: 4.5em;
    font-size: 14px;
    padding: 10px;
}

.radio-gender {
    display: block;
}

.moreInfo {
    width: 100%;
    height: 15vh;
}

.btn-submit {
    align-content: center;
}

.lastName {
    margin-left: 10px;
    width: 48%;
}

.radio-selection {
    font-size: 15px;
}

img {
    width: 20%;
    height: auto;
  }

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 25%;
}

@media (max-width: 1000px) {


}

@media (max-width: 700px) {
    .container-inner {
        width: 90vw;
        height: 100%;
    }
    .container-inner-form {
        width: 90vw;
        height: 100%;
        padding: 10px;
    }

    .center {
        width: 40%;
    }

    /* .field-element,
    .section-header,
    .form-title,
    .container-inner-form,
    .form-style,
    .inline,
    .inline-2,
    .inline-4,
    .radio-selection
      { font-size: 1em !important;  }  */

    .inline-2, .inline-4 {
        padding: 0;
    }
}

@media (max-width: 479px) {
    .container-inner {
        width: 90vw;
        height: 100%;
    }
    .container-inner-form {
        width: 90vw;
        height: 100%;
        padding: 10px;
    }

    .center {
        width: 70%;
    }

    .field-element,
    .section-header,
    .form-title,
    .container-inner-form,
    .form-style,
    .inline,
    .inline-2,
    .inline-4,
    .radio-selection
      { font-size:0.9em !important;  } 

    .inline-2, .inline-4 {
        padding: 0;
    }
}