/* Container for flexboxes */
section-flex {
    display: -webkit-flex;
    display: flex;
  }

  /* Style the content */
article {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 10px;
  }

nav-filler {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.revert {
    display: block;
}

.appointment-text {
    min-height: 2.6em;
    font-size: 1.45em;
}

.booked-info-box {
  border: darkslategray;
  border-style: solid;
  margin-bottom: 0;
}

@media (max-width: 769px) {
    nav-filler {
        -webkit-flex: 0;
        -ms-flex: 0;
        flex: 0;
    }
}

  @media (max-width: 600px) {
    section-flex {
      -webkit-flex-direction: column;
      flex-direction: column;
    }
   
  }