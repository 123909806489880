.title {
    text-align: center;
    margin: 2rem;
}

.page-name {
    color: white;
    font-family: 'Chalk';
}

.home-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 20%;
}

.about-lower-container {
    text-align: left;
    width: 100%;
    padding-right: 125px;
    padding-left: 125px;
    margin-right: auto;
    margin-left: auto;
    margin: auto;
}

.topic-header {
    color: rgb(37, 134, 167);
    font-weight:bold;
}

.enroll-btn {
    font-size: 20px;
}

.container-inner {
    font-size: 1.2em;
}

.enrollment-cards {
    /* height: 80%; */
}

.enrollment-card-title {
    padding: 0;
}

.under-logo {
    margin: 0;
}

.pic-select {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

@media (max-width: 1000px) {

}

@media (max-width: 479px) {
    .enroll-btn {
        font-size: .8em;
    }
}