.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.title {
  /* font-family: 'Marck Script', cursive; */
  font-size: 70px;
  color: white;
text-shadow: 1px 0 #888888;
letter-spacing:1px;
font-weight:bold;
}

@media (max-width: 479px) {
  .title {
      font-size: 40px;
  }
}