.modal-wrapper {
    /* background-color: white; */
    position: fixed;
    top: 20px;
    right: 20px;
    width: 15%;
    max-width: 150px;
    /* margin-bottom: 20px;
    margin-right: 20px; */
    z-index: 9999;
}

.modal-header {
    padding: 0;
    text-align: center;
}

.modal-body {
    /* background-color: lightpink; */
    border-radius: 10px;
    padding: 0;
    text-align: center;
}

@media (max-width: 1000px) {
    .modal-wrapper {
        display: none;
    }
}