.title {
    text-align: center;
    margin: 2rem;
    /* text-decoration: none !important; */
}

.title-anchor a:hover{
    text-decoration: none;
}

.container-inner-schedule {
    background-color: rgb(5, 136, 64);
    border-style: solid;
    border-width: 5px;
    border-color: 	rgb(193, 154, 107);
    border-radius: 25px;
    text-align: center;
    height: 69.5vh;
    width: 80vw;
    margin: auto;
    font-size: 22px;
    font-family: 'Chalk';
    color: white;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    /* font-size: 2vw; */
}

.container-inner::-webkit-scrollbar {
    -webkit-appearance: none;
}

.container-inner::-webkit-scrollbar:vertical {
    width: 11px;
}

.container-inner::-webkit-scrollbar-thumb {
    border-radius: 8px;
    /* border: 2px solid white; */
    background-color: rgba(0, 0, 0, .4);
}

.messages-margin {
    padding: 2em;
}

@media (max-width: 479px) {
    .container-inner {
        height: 50vh;
        font-size: 14px;
    }
    h1 {
        font-size: 25px;
    }

    h2 {
        font-size: 20px;
    }


    h3 {
        font-size: 18px;
    }

    h4 {
        font-size: 14px;
    }
}

.card1 {
    max-width: 20rem;
    margin: .5rem .5rem;
}

.cards-container {
    display: flex;
    text-align: center;
}

.card {
    margin: 0 auto; /* Added */
    float: none; /* Added */
    margin-bottom: 10px; /* Added */
}

.home-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 20%;
}

.center-title {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.title-img {
    width: 30vw;
    border-radius: 10px;
}


@media (max-width: 479px) {

}

@media (max-width: 479px) {
    .container-inner {
        width: 90vw;
        height: 100%;
    }
}