.nav-item {
    margin: 0 .5rem;
}

.navbar {
    /* padding-bottom: 5rem; */
}

.nav-font {
    margin-left: auto !important;
    margin-right: auto !important;
}

@media (max-width: 479px) {
    .nav-font {
        font-size: 12px;
    }
}

.navbar__link:hover {
    animation: bounce .8s infinite; 
   -webkit-animation: bounce .8s infinite; 
   -moz-animation: bounce .8s infinite; 
   -o-animation: bounce .8s infinite; 
  }

.navbar__link--active {
    border-bottom: 3px solid #29b6f6;
    transition: border-bottom .5s ease-in-out;
}

@-webkit-keyframes bounce {
    0%,  
    100% {
       -webkit-transform: translateY(0);
    } 
    50% {
        -webkit-transform: translateY(-10px);
    }
}
@-moz-keyframes bounce {
    0%, 
    100% {
       -moz-transform: translateY(0);
    }
    50% {
       -moz-transform: translateY(-10px);
    }
}
@-o-keyframes bounce {
    0%,
    100% {
       -o-transform: translateY(0);
    }
    50% {
       -o-transform: translateY(-10px);
    }
}
@keyframes bounce {
    0%,  
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}