.title {
    text-align: center;
    margin: 2rem;
}

.page-name {
    color: white;
    font-family: 'Chalk';
}

.card1 {
    max-width: 20rem;
    margin: .5rem .5rem;
}

.cards-container {
    display: flex;
    text-align: center;
}

.card {
    margin: 0 auto; /* Added */
    float: none; /* Added */
    margin-bottom: 10px; /* Added */
}

.home-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 20%;
}

.philosophy-lower-container {
    text-align: left;
    width: 100%;
    padding-right: 2vw;
    padding-left: 2vw;
    margin-right: auto;
    margin-left: auto;
    margin: auto;
}

.topic-header {
    color: rgb(37, 134, 167);
    font-weight:bold;
}

/* @media (max-width: 1000px) {

} */
@media (max-width: 1000px) {

}

@media (max-width: 479px) {

}